<template data-testid="tour-comparison-table">
  <v-container class="pa-0">
    <v-row>
      <v-col>
        <p class="heading-h4" data-testid="tour-comparison-table-title">
          {{ t('Title_.subTitle.tourSummary') }}
        </p>
        <v-data-table :headers="headers" :items="items" hide-default-footer>
          <template #[`item.objectType`]="{ item }">
            <span class="body-text-3">{{ t(`Msg_.${item.objectType}`) }}</span>
          </template>
          <template #[`item.profit`]="{ item }">
            <span
              data-testid="tour-comparison-table-profit-field"
              :class="['body-text-3', profitClass(item)]"
            >
              {{ item.profit }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store.js';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';

const toursStore = useToursStore();

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local Variables ---
const items = ref([]);

// --- Methods ---
const percentageText = (value) => {
  if (value === null) {
    return '-';
  }
  const prefix = value > 0 ? '+' : '';
  const suffix = '%';
  return prefix + value + suffix;
};

const profitClass = (item) => {
  let value = null;
  if (item.objectType === 'comparison') {
    value = parseInt(item.profit.replace('%', ''));
  } else {
    value = helpers.convertCurrencyToFloat(item.profit);
  }

  return value >= 0 ? 'positive-profit-color' : 'negative-profit-color';
};

const getItems = () => {
  items.value = [
    formatObjectWithValues('expected'),
    formatObjectWithValues('actual'),
    formatObjectWithPercentages(),
  ];
};

const formatObjectWithValues = (type) => {
  const object = toursStore.tourComparison[type];
  const valueProperties = [
    'billableTimeInMinutes',
    'otherTimeInMinutes',
    'drivingTimeInMinutes',
    'totalTimeInMinutes',
    'cost',
    'revenue',
    'profit',
  ];
  const formattedObject = { objectType: type };

  valueProperties.forEach((property) => {
    if (property.includes('InMinutes')) {
      formattedObject[property.replace('InMinutes', '')] =
        helpers.convertMinutesIntoHoursAndDays(object[property]);
    } else {
      formattedObject[property] =
        '€' + helpers.convertValueToCurrency(object[property]);
    }
  });
  return formattedObject;
};

const formatObjectWithPercentages = () => {
  const object = toursStore.tourComparison.comparison;
  const percentageProperties = [
    'billableTimePercentage',
    'otherTimePercentage',
    'drivingTimePercentage',
    'totalTimePercentage',
    'costPercentage',
    'revenuePercentage',
    'profitPercentage',
  ];
  const formattedObject = { objectType: 'comparison' };

  percentageProperties.forEach((property) => {
    formattedObject[property.replace('Percentage', '')] = percentageText(
      object[property]
    );
  });
  return formattedObject;
};

// --- Computed ---
const headers = computed(() => {
  return [
    {
      text: '',
      value: 'objectType',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.billableTime'),
      value: 'billableTime',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.other'),
      value: 'otherTime',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.driving'),
      value: 'drivingTime',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.totalTime'),
      value: 'totalTime',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.cost'),
      value: 'cost',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.revenue'),
      value: 'revenue',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.profit'),
      value: 'profit',
      class: 'overline-2 px-1',
      align: 'center',
      sortable: false,
    },
  ];
});

// --- Lifecycle Hook ---
getItems();
</script>

<style lang="scss" scoped>
.v-data-table::v-deep th {
  color: $grey-regular !important;
  font-size: 10px !important;
}

.positive-profit-color {
  color: $caribbean-dark !important;
}
.negative-profit-color {
  color: $red-danger !important;
}
</style>
