import { render, staticRenderFns } from "./tour-comparison.vue?vue&type=template&id=663fa5f8&scoped=true&data-testid=tour-comparison&"
import script from "./tour-comparison.vue?vue&type=script&setup=true&lang=js&"
export * from "./tour-comparison.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./tour-comparison.vue?vue&type=style&index=0&id=663fa5f8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663fa5f8",
  null
  
)

export default component.exports