<template data-testid="tour-comparison-card">
  <v-card
    class="pa-0 not-selectable relative-position rounded-lg shadow-regular"
    :class="{ disabled: !tour.hasActualInformation }"
  >
    <div
      v-if="tour.shiftId === toursStore.selectedComparisonTour"
      class="pa-0 ma-0 border-selected"
    ></div>
    <v-container class="pa-6" @click="emit('click', tour.shiftId)">
      <v-row class="ma-0 pb-0">
        <v-col
          class="pa-0 d-flex justify-space-between"
          :class="tourNameClass"
          data-testid="tour-comparison-card-name-field"
        >
          <div>
            <v-icon
              v-if="tour.hasActualInformation"
              :size="shiftOpenIcon.size"
              class="shift-closed-icon pr-1"
              :color="shiftOpenIcon.color"
            >
              {{ shiftOpenIcon.icon }}
            </v-icon>
            <span>
              {{ tour.shiftName }}
            </span>
          </div>
          <div v-if="!tour.hasActualInformation">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon
                    class="pa-0 ma-0 pr-2"
                    color="yellowRegular"
                    size="20"
                  >
                    {{ 'mdi-alert' }}
                  </v-icon>
                </div>
              </template>
              <span>{{ t('Msg_.informationNotAvailable') }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0 pt-0 pb-3">
        <v-col
          data-testid="tour-comparison-card-employee-complete-name-field"
          class="pl-0 pt-1"
        >
          <span class="pa-0 body-text-1 grey-dark">
            {{ tour.externalEmployeeJobCode }}
          </span>
          <span class="pa-0 body-text-2 grey-dark">
            {{ tour.employeeCompleteName }}
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="ma-0 pt-3">
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 grey-regular pb-2">
            {{ t('Msg_.billableTime') }}
          </div>
          <div class="d-flex justify-center">
            <RegularBadge
              :text="percentageText(tour.billableTimePercentage)"
              :color="regularBadgeColor()"
              :text-color="regularBadgeTextColor()"
              class="body-text-4 text-center mb-0"
              data-testid="tour-comparison-card-billable-time-field"
            />
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 grey-regular pb-2">
            {{ t('Msg_.totalTime') }}
          </div>

          <div class="d-flex justify-center">
            <RegularBadge
              :text="percentageText(tour.totalTimePercentage)"
              :color="regularBadgeColor()"
              :text-color="regularBadgeTextColor()"
              class="body-text-4 text-center mb-0"
              data-testid="tour-comparison-card-total-time-field"
            />
          </div>
        </v-col>
        <v-col class="pa-0">
          <div class="d-flex justify-center overline-2 grey-regular pb-2">
            {{ t('Msg_.profit') }}
          </div>
          <div class="d-flex justify-center">
            <RegularBadge
              :text="percentageText(tour.profitPercentage)"
              :color="regularBadgeColor(tour.profitPercentage)"
              :text-color="regularBadgeTextColor(tour.profitPercentage)"
              class="body-text-3 text-center mb-0"
              data-testid="tour-comparison-card-profit-field"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store.js';
import languages from '../../locales/languages.js';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const toursStore = useToursStore();
const emit = defineEmits(['click']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  tour: {
    type: Object,
    required: true,
  },
});

// --- Methods ---
const percentageText = (value) => {
  if (value === null) {
    return '-';
  }
  const prefix = value > 0 ? '+' : '';
  const suffix = '%';
  return prefix + value + suffix;
};

const regularBadgeColor = (value) => {
  if (!value || !props.tour.hasActualInformation) {
    return 'ashLight';
  }
  return value >= 0 ? 'lowOpacityGreen' : 'redErrorLight';
};

const regularBadgeTextColor = (value) => {
  if (!props.tour.hasActualInformation) {
    return 'greyRegular';
  }

  if (!value) {
    return '';
  }

  return value >= 0 ? 'caribbeanDark' : 'redDanger';
};

// --- Computed ---
const tourNameClass = computed(() => {
  return props.tour.shiftId === toursStore.selectedComparisonTour
    ? 'subtitle-text-1'
    : 'subtitle-text-2';
});

const shiftOpenIcon = computed(() => {
  const iconSize = toursStore.selectedComparisonTour ? 22 : 20;
  const closedIcon = {
    icon: 'mdi-lock-outline',
    color: 'caribbeanDark',
    size: iconSize,
  };
  const openedIcon = {
    icon: 'mdi-lock-open-outline',
    color: 'redDanger',
    size: iconSize,
  };

  return props.tour.isShiftClosed ? closedIcon : openedIcon;
});
</script>

<style lang="scss" scoped>
.grey-regular {
  color: $grey-regular;
}
.grey-dark {
  color: $grey-dark;
}

.border-selected {
  position: absolute;
  background-color: $blue-regular;
  width: 8px;
  height: 100%;
  border-radius: 10px 0px 0px 10px !important;
}

.disabled {
  background-color: $ash-light !important;
  color: $grey-regular !important;
}
.not-selectable {
  -webkit-touch-callout: none; /* iPhone OS, Safari */
  -webkit-user-select: none; /* Chrome, Safari 3 */
  -khtml-user-select: none; /* Safari 2 */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* likely future implementation */
}

.relative-position {
  position: relative;
}
</style>
