<template data-testid="tour-comparison">
  <v-container class="pa-0" v-if="!loadingStore.isGlobalLoading">
    <v-row class="mt-6" v-if="toursStore.comparisonTours.length > 0">
      <v-col
        cols="4"
        class="tours-scrollable-content py-0"
        data-testid="tour-comparison-cards-container"
        id="tours-container"
      >
        <TourComparisonCard
          :tour="item"
          class="mb-6"
          :class="{ clickable: item.hasActualInformation }"
          :id="`tour-${item.shiftId}`"
          :data-testid="`tour-comparison-card-${i}`"
          v-for="(item, i) in toursStore.comparisonTours"
          :key="i"
          @click="onTourCardClicked(item)"
        />
      </v-col>
      <v-col
        cols="8"
        v-if="loadingStore.isPartialLoading"
        class="d-flex justify-center align-center"
      >
        <LoadingAnimation dense />
      </v-col>
      <v-col
        cols="8"
        class="py-0"
        v-else-if="toursStore.selectedComparisonTour"
      >
        <v-card
          class="rounded-lg shadow-regular"
          data-testid="tour-comparison-details"
          v-if="toursStore.tourComparison"
        >
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="pa-8">
                <v-card-title class="pa-0">
                  <v-icon
                    size="24"
                    :class="shiftOpenIcon.icon"
                    :color="shiftOpenIcon.color"
                    data-testid="tour-comparison-shift-closed-icon"
                  >
                    {{ shiftOpenIcon.icon }}
                  </v-icon>
                  <span
                    class="heading-h3 pl-3"
                    data-testid="tour-comparison-tour-name-field"
                  >
                    {{ toursStore.tourComparison.shiftName }}
                  </span>
                </v-card-title>
                <v-card-text class="pa-0">
                  <TourComparisonHeader class="mt-8" />
                  <v-divider class="mt-8" />
                  <TourComparisonTable class="mt-8" />
                  <TourTasksComparison
                    class="mt-8"
                    v-if="toursStore.tourComparison.tasks?.length > 0"
                  />
                  <TourComparisonPrescriptions
                    class="mt-8"
                    :prescriptions="toursStore.tourComparison.prescriptions"
                  />
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="8" class="py-0" v-else>
        <EmptyListMessage
          data-testid="tours-comparison-no-tour-empty-message"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <EmptyListMessage data-testid="tours-dashboard-no-tour-empty-message" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onBeforeUnmount, computed } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useToursStore } from '@/store/pinia/tours.store.js';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import TourComparisonCard from './tourComparisonCards/tour-comparison-card.vue';
import TourComparisonHeader from './tour-comparison-header.vue';
import TourComparisonTable from './tour-comparison-table.vue';
import TourTasksComparison from './tour-tasks-comparison.vue';
import TourComparisonPrescriptions from './tour-comparison-prescriptions.vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const loadingStore = useLoadingStore();
const toursStore = useToursStore();

// --- Method ---
const onTourCardClicked = async (item) => {
  if (!item.hasActualInformation) {
    return;
  }
  trackPage(toursStore.selectedHub, {
    resourceId: item.shiftId,
    routeName: 'tourComparison.shiftDetails',
  });
  await toursStore.getTourComparisonDetails(item.shiftId);
};

// --- Computed ---
const shiftOpenIcon = computed(() => {
  const closedIcon = {
    icon: 'mdi-lock-outline',
    color: 'caribbeanDark',
  };
  const openedIcon = {
    icon: 'mdi-lock-open-outline',
    color: 'redDanger',
  };

  return toursStore.tourComparison.isShiftClosed ? closedIcon : openedIcon;
});

// --- Lifecycle hook ---
onBeforeUnmount(() => {
  toursStore.tourComparison = null;
  toursStore.comparisonTours = [];
});
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
}

.tours-scrollable-content {
  /* min height: 100% of page height minus (header title and selector container height (250px) plus footer and padding height (75px)) */
  min-height: calc(100vh - 325px);

  /* max height: 100% of page height minus (header title and selector container height (250px))*/
  max-height: calc(100vh - 250px);
  overflow: auto;
}
</style>
