<template data-testid="tour-tasks-comparison">
  <v-container class="pa-0">
    <v-row>
      <v-col class="d-flex align-center">
        <span class="heading-h4">{{ t('Title_.subTitle.tasks') }}</span>

        <div class="number-tasks ml-2 d-flex justify-center">
          <span
            class="blueRegular--text"
            data-testid="tour-task-comparison-number-tasks "
          >
            {{ tasks.length }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p class="subtitle-text-2">{{ t('Msg_.actual') }}</p>
      </v-col>
      <v-col cols="6">
        <p class="subtitle-text-2">{{ t('Msg_.expected') }}</p>
      </v-col>
    </v-row>
    <v-row v-for="(task, index) in tasks" :key="index">
      <v-col cols="6">
        <TourTaskComparisonCard
          :task="getActualTask(task)"
          :data-testid="`tour-actual-task-card-${index}`"
          :has-services="task.services?.length > 0"
          @display-services-dialog="displayServicesDialog(task)"
        />
      </v-col>
      <v-col cols="6">
        <TourTaskComparisonCard
          v-if="task.expectedInformation"
          disabled
          :task="getExpectedTask(task)"
          :data-testid="`tour-expected-task-card-${index}`"
        />
        <div v-else class="d-flex align-center pa-4 body-text-3">
          <v-icon color="redError" class="mr-2">
            mdi-alert-circle-outline
          </v-icon>
          <span>{{ t('Msg_.taskNotPlanned') }}</span>
        </div>
      </v-col>
    </v-row>
    <TourComparisonServicesDialog
      :display-dialog="displayDialog"
      :task="servicesDialogTask"
      @close-dialog="closeServicesDialog"
    />
  </v-container>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useToursStore } from '@/store/pinia/tours.store';
import languages from '../locales/languages';
import TourTaskComparisonCard from './tourComparisonCards/tour-task-comparison-card.vue';
import TourComparisonServicesDialog from './tour-comparison-services-dialog.vue';

const toursStore = useToursStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local Variables
const displayDialog = ref(false);
const servicesDialogTask = ref({});

// --- Methods ---
const getTaskName = (task) => {
  const isTour = task.type.Value === 2;
  if (isTour) {
    return task.patientInformation?.patientName;
  }
  return task.externalTaskName;
};

const getActualTask = (task) => {
  let actualTask = getTask(task, 'actualInformation');
  actualTask.hasUnexpectedServices = taskHasUnexpectedServices(task);
  return actualTask;
};

const getExpectedTask = (task) => {
  return getTask(task, 'expectedInformation');
};

const getTask = (task, type) => {
  return {
    ...task[type],
    name: getTaskName(task),
  };
};

const taskHasUnexpectedServices = (task) => {
  return !!task.services?.find(
    (service) => !service.wasPerformed || !service.wasPlanned
  );
};

const displayServicesDialog = (task) => {
  servicesDialogTask.value = task;
  displayDialog.value = true;
};

const closeServicesDialog = () => {
  displayDialog.value = false;
  servicesDialogTask.value = {};
};

// --- Computed ---
const tasks = computed(() => {
  return toursStore.tourComparison.tasks;
});
</script>

<style lang="scss" scoped>
.number-tasks {
  height: 18px;
  min-width: 18px !important;
  border-radius: 18px;
  border: solid 1.5px $blue-regular;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 18px;
}
</style>
