<template data-testid="tour-comparison-header">
  <v-container class="pa-0">
    <v-row justify="start">
      <v-col cols="auto">
        <span
          class="body-text-1 greyDark--text"
          data-testid="tour-details-noventi-employee-job-code-field"
        >
          {{ toursStore.tourComparison.externalEmployeeJobCode }}
        </span>
        <span
          class="body-text-2"
          data-testid="tour-details-employee-name-field"
          :class="canRouteToContractInformation ? 'employee-redirect' : ''"
          @click="redirectToEmployeeContractInformation()"
        >
          {{ toursStore.tourComparison.employeeCompleteName }}
        </span>
      </v-col>
      <v-col cols="8">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="auto" class="pr-0">
            <span class="overline-2 grey-regular px-0">
              {{ t('Msg_.hourlyCost') }}
            </span>
          </v-col>
          <v-col cols="auto">
            <RegularBadge
              class="body-text-3"
              :text="
                '€ ' +
                helpers.convertValueToCurrency(
                  toursStore.tourComparison.hourlyCost
                )
              "
              color="lowOpacityGreen"
              text-color="caribbeanDark"
              data-testid="tour-card-profit-field"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRouter } from 'vue-router/composables';
import { useToursStore } from '@/store/pinia/tours.store.js';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const router = useRouter();
const toursStore = useToursStore();
const { canUser } = usePermissions();

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const redirectToEmployeeContractInformation = () => {
  if (canRouteToContractInformation.value) {
    router.push({
      name: 'EmployeeProfileContractInformation',
      params: { employeeId: toursStore.tourComparison.employeeId },
    });
  }
};

// --- Computed ---
const canRouteToContractInformation = computed(() => {
  return (
    !!toursStore.tourComparison.employeeId &&
    canUser(['People.Employees.ReadContract'])
  );
});
</script>

<style lang="scss" scoped>
.employee-redirect {
  cursor: pointer;
  text-decoration: underline;
}
</style>
