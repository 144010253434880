<template>
  <v-dialog
    :value="displayDialog"
    :retain-focus="false"
    persistent
    width="454"
    data-testid="tour-comparison-services-dialog"
  >
    <v-card>
      <v-container class="py-6 px-8">
        <v-row>
          <v-col class="py-2 d-flex align-center">
            <RegularBadge
              color="#E9F1F6"
              class="badge-text-size"
              text-color="blueRegular"
              :text="convertTime(task.actualInformation?.startTimeInMinutes)"
              data-testid="tour-comparison-services-dialog-start-time-field"
            />
            <span
              class="card-title pl-4 mineBlack--text"
              data-testid="tour-comparison-services-dialog-patient-name-field"
            >
              {{ getTaskName(task) }}
            </span>
          </v-col>
          <v-col cols="auto" class="text-end py-2">
            <v-icon
              size="28"
              cols="auto"
              @click="closeDialog()"
              color="mineBlack"
              data-testid="tour-comparison-services-dialog-close-btn"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto" class="py-2">
            <p class="overline-2 greyRegular--text mb-1">
              {{ t('Msg_.duration') }}
            </p>
            <div class="d-flex align-center">
              <RegularBadge
                class="badge-text-size"
                color="ashLight"
                text-color="#212121"
                :text="convertTime(task.actualInformation?.durationInMinutes)"
                data-testid="tour-comparison-services-dialog-duration-field"
              />
              <span
                class="caption-3 px-2 greyRegular--text"
                data-testid="tour-comparison-services-dialog-duration-difference-field"
              >
                {{ getTimeDifference('durationInMinutes') }}
              </span>
            </div>
          </v-col>
          <v-col cols="auto" class="py-2">
            <p class="overline-2 greyRegular--text mb-1">
              {{ t('Msg_.driving') }}
            </p>
            <div class="d-flex align-center">
              <RegularBadge
                class="badge-text-size"
                color="ashLight"
                text-color="#212121"
                :text="
                  convertTime(task.actualInformation?.drivingTimeInMinutes)
                "
                data-testid="tour-comparison-services-dialog-driving-field"
              />
              <span
                class="caption-3 px-2 greyRegular--text"
                data-testid="tour-comparison-services-dialog-driving-difference-field"
              >
                {{ getTimeDifference('drivingTimeInMinutes') }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col>
            <span class="overline-2 mineBlack--text pb-2">
              {{ t('Msg_.plannedServices') }}
            </span>
            <div
              class="body-text-2 pt-4 d-flex align-center"
              v-for="(service, index) in servicesPlanned"
              :key="index"
            >
              <v-icon
                size="18"
                color="caribbeanRegular"
                v-if="service.wasPerformed"
              >
                mdi-check
              </v-icon>
              <v-icon size="18" color="redDanger" v-else> mdi-close </v-icon>
              <span
                :class="getServiceClass(service)"
                :data-testid="`tour-comparison-services-dialog-service-planned-${index}`"
              >
                {{ service.externalServiceName }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="servicesAddedDuringVisit?.length > 0" class="pt-4">
          <v-col>
            <span class="overline-2 mineBlack--text pb-2">
              {{ t('Msg_.servicesAddedDuringTheVisit') }}
            </span>
            <div
              class="body-text-2 pt-4 d-flex align-center"
              v-for="(service, index) in servicesAddedDuringVisit"
              :key="index"
            >
              <v-icon size="18" color="yellowDark"> mdi-plus </v-icon>
              <span
                class="pl-2"
                :data-testid="`tour-comparison-services-dialog-service-not-planned-${index}`"
              >
                {{ service.externalServiceName }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['close-dialog']);

// --- Props ---
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },

  displayDialog: {
    type: Boolean,
    required: true,
  },
});

// --- Methods ---
const getTaskName = (task) => {
  const isTour = task.type?.Value === 2;
  if (isTour) {
    return task.patientInformation?.patientName;
  }
  return task.externalTaskName;
};

const convertTime = (value) => {
  if (value) {
    return helpers.convertMinutesIntoHoursAndDays(value);
  }
  return '-';
};

const getTimeDifference = (property) => {
  if (!props.task.actualInformation || !props.task.expectedInformation) {
    return '';
  }
  const difference =
    props.task.actualInformation[property] -
    props.task.expectedInformation[property];
  return difference >= 0 ? '+' + difference : difference;
};

const closeDialog = () => {
  emit('close-dialog');
};

const getServiceClass = (service) => {
  return service.wasPerformed ? 'pl-2' : 'pl-2 text-decoration-line-through';
};

// --- Computed ---
const servicesPlanned = computed(() => {
  return props.task.services?.filter((service) => service.wasPlanned);
});

const servicesAddedDuringVisit = computed(() => {
  return props.task.services?.filter(
    (service) => !service.wasPlanned && service.wasPerformed
  );
});
</script>

<style lang="scss" scoped>
.badge-text-size {
  font-size: 10px;
}
</style>
