<template>
  <v-container class="pa-0">
    <v-row>
      <v-col>
        <span class="heading-h4" data-testid="tour-comparison-table-title">
          {{ t('Title_.subTitle.missingAndExpiredPrescriptions') }}
        </span>
        <span
          class="heading-h4 blueRegular--text"
          data-testid="tour-details-missing-expired-prescriptions-quantity-field"
        >
          {{ expiredAndMissingPrescriptions.length || 0 }}
        </span>
        <v-container>
          <v-row dense>
            <v-col
              cols="4"
              v-for="(prescription, index) in expiredAndMissingPrescriptions"
              :key="index"
            >
              <TourPrescriptionCard
                :prescription="prescription"
              ></TourPrescriptionCard>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import languages from '../locales/languages';
import { defineProps, computed } from 'vue';
import TourPrescriptionCard from '../sharedCards/tour-prescription-card.vue';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  prescriptions: {
    type: Array,
    required: true,
  },
});

// --- Local Variables ---
const prescriptionTypes = {
  expiring: 2,
  expired: 3,
  missing: 4,
};

// --- Computed ---
const expiredAndMissingPrescriptions = computed(() => {
  return props.prescriptions.filter(
    (prescription) =>
      prescription.status.Value === prescriptionTypes.expired ||
      prescription.status.Value === prescriptionTypes.missing
  );
});
</script>

<style></style>
