<template>
  <v-card
    :disabled="disabled"
    class="rounded-0"
    elevation="0"
    :color="cardColor"
  >
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="pa-0">
            <RegularBadge
              color="#E9F1F6"
              class="badge-text-size"
              text-color="blueRegular"
              :text="convertTime(task.startTimeInMinutes)"
              data-testid="tour-task-comparison-card-start-time-field"
            />
            <a
              v-if="showViewDetails"
              class="pl-4 button-1 text-center blueRegular--text"
              @click="viewDetails()"
              data-testid="tour-task-comparison-card-view-details-link"
            >
              {{ t('Msg_.viewDetails') }}
            </a>
          </v-col>
        </v-row>

        <v-row class="pt-4">
          <v-col class="pa-0 d-flex align-center">
            <div class="task-order d-flex justify-center">
              <span data-testid="tour-task-comparison-card-order">
                {{ task.order || '-' }}
              </span>
            </div>
            <span
              class="body-text-3 pl-2 mineBlack--text"
              :class="{ 'text-decoration-line-through': !task.order }"
              data-testid="tour-task-comparison-card-patient-name-field"
            >
              {{ task.name }}
            </span>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col cols="6" class="pa-0 d-flex flex-row justify-space-between">
            <div>
              <p class="overline-2 greyRegular--text mb-1">
                {{ t('Msg_.duration') }}
              </p>

              <RegularBadge
                color="ashLight"
                class="badge-text-size"
                text-color="#212121"
                :text="convertTime(task.durationInMinutes)"
                data-testid="tour-task-comparison-card-duration-field"
              />
            </div>
            <div>
              <p class="overline-2 greyRegular--text mb-1">
                {{ t('Msg_.driving') }}
              </p>
              <RegularBadge
                color="ashLight"
                class="badge-text-size"
                text-color="#212121"
                :text="convertTime(task.drivingTimeInMinutes)"
                data-testid="tour-task-comparison-card-driving-field"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';

const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['display-services-dialog']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  task: {
    type: Object,
    required: true,
  },

  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  hasServices: {
    type: Boolean,
    required: false,
    default: null,
  },
});

// --- Methods ---
const viewDetails = () => {
  emit('display-services-dialog');
};

const convertTime = (value) => {
  if (value) {
    return helpers.convertMinutesIntoHoursAndDays(value);
  }
  return '-';
};

// --- Computed ---
const showViewDetails = computed(() => {
  return props.hasServices;
});

const cardColor = computed(() => {
  if (props.task.hasUnexpectedServices) {
    return '#FFE0E0';
  }
  return '';
});
</script>

<style lang="scss" scoped>
.task-order {
  height: 16px;
  min-width: 16px !important;
  border-radius: 16px;
  border: solid 1.5px #212121;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 16px;
  color: #212121;
}

.badge-text-size {
  font-size: 10px;
}
</style>
