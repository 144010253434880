import { render, staticRenderFns } from "./tour-comparison-card.vue?vue&type=template&id=14e13d35&scoped=true&data-testid=tour-comparison-card&"
import script from "./tour-comparison-card.vue?vue&type=script&setup=true&lang=js&"
export * from "./tour-comparison-card.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./tour-comparison-card.vue?vue&type=style&index=0&id=14e13d35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e13d35",
  null
  
)

export default component.exports